import {createStylesParams, StyleParamType, wixColorParam, wixFontParam} from '@wix/tpa-settings'

export type DetailsSectionStylesParams = {
  detailsSectionBackgroundColor: StyleParamType.Color
  detailsSectionBorderColor: StyleParamType.Color
  detailsSectionBorderWidth: StyleParamType.Number
  detailsSectionBorderRadius: StyleParamType.Number
  detailsSectionSubtitleFont: StyleParamType.Font
  detailsSectionSubtitleColor: StyleParamType.Color
  detailsSectionBodyTextFont: StyleParamType.Font
  detailsSectionBodyTextColor: StyleParamType.Color
}

export const detailsSectionStylesParams = createStylesParams<DetailsSectionStylesParams>({
  detailsSectionBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.05),
  },
  detailsSectionBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: (params) => wixColorParam('color-5', params.isMobile ? 1 : 0)(params),
  },
  detailsSectionBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  detailsSectionBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },

  detailsSectionSubtitleFont: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: wixFontParam('Page-title', {size: 16, htmlTag: 'h2'}),
  },
  detailsSectionSubtitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  detailsSectionBodyTextFont: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: wixFontParam('Body-M', {size: 16}),
  },
  detailsSectionBodyTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
})
