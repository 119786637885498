import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings'

export type DisplaySettingsParams = {
  showBreadcrumbs: SettingsParamType.Boolean
}

export const DEFAULT_DISPLAY_SETTINGS_PARAMS = {
  showBreadcrumbs: true,
}

export const displaySettingsParams = createSettingsParams<DisplaySettingsParams>({
  showBreadcrumbs: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => DEFAULT_DISPLAY_SETTINGS_PARAMS.showBreadcrumbs,
  },
})
