import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import {Status} from '@wix/ambassador-table-reservations-v1-reservation/types'
import format from 'date-fns/format'
import React from 'react'
import {Text} from 'wix-ui-tpa/cssVars'

import {LayoutSize} from '../../../../components-shared/LayoutSizeProvider/types'

import {classes, st} from './Counter.st.css'
import {useHooks} from './useHooks'

interface CountdownTimerProps {
  layoutSize: LayoutSize
}

export const CountdownTimer: React.FC<CountdownTimerProps> = ({layoutSize}) => {
  const {leftSec, reservation} = useHooks()
  const {t} = useTranslation()
  const {isSSR} = useEnvironment()

  const formattedTime = format(leftSec * 1000, 'mm:ss')
  const isStatusHeldOrPending =
    reservation?.status === Status.HELD ||
    reservation?.status === Status.PAYMENT_PENDING ||
    reservation?.status === Status.PAYMENT_INFORMATION_PENDING

  return (
    <section
      id="tr-timer"
      aria-flowto="tr-details"
      className={st(classes.root, {[layoutSize]: true})}
    >
      {!isSSR ? (
        <Text className={classes.text} role={leftSec === 0 ? 'alert' : undefined} tagName="p">
          {leftSec > 0 && isStatusHeldOrPending
            ? t('uou-reservations.reservation-details.timer.held-for', {
                time: formattedTime,
              })
            : t('uou-reservations.reservation-details.timer.held-for-expired')}
        </Text>
      ) : null}
    </section>
  )
}
