import React, {FC} from 'react'
import {WidgetProps} from '@wix/yoshi-flow-editor'
import {Text} from 'wix-ui-tpa/cssVars'
import {useSettings, useStyles} from '@wix/tpa-settings/react'

import {CountdownTimer} from '../components/CountdownTimer'
import {ReservationDetailsSection} from '../components/ReservationDetailsSection'
import {Form} from '../components/Form'
import {withReservationDetailsStorageProvider} from '../storage'
import {ReservationErrorModal} from '../components/ReservationErrorModal'
import {stylesParams} from '../stylesParams'
import {settingsParams} from '../settingsParams'
import {RtlProvider} from '../../../components-shared/RtlProvider'
import {LayoutSize} from '../../../components-shared/LayoutSizeProvider/types'
import {withLayoutSizeProvider} from '../../../components-shared/LayoutSizeProvider'
import {Breadcrumbs} from '../../../components-shared/Breadcrumbs'

import {useHooks} from './useHooks'
import {classes, st} from './Widget.st.css'
import {LAYOUT_BREAKPOINTS_START, reservationDetailsDataHooks} from './constants'

const Widget: FC<WidgetProps> = () => {
  const {
    reservation,
    isManualApproval,
    layoutSize,
    currentMember,
    shouldShowAutoApproveText,
    breadcrumbsConfig,
  } = useHooks()
  const isAccordionLayout = layoutSize === LayoutSize.XS || layoutSize === LayoutSize.S
  const styles = useStyles()
  const settings = useSettings()
  const showBreadcrumbs = settings.get(settingsParams.showBreadcrumbs)

  const titleTag =
    styles.get(stylesParams.titleFont).htmlTag ??
    styles.getDefaultValue(stylesParams.titleFont).htmlTag

  const titleText = settings.get(
    shouldShowAutoApproveText ? settingsParams.titleText : settingsParams.titleRequestText,
  )

  if (!reservation?.id && !isManualApproval) {
    return <ReservationErrorModal />
  }

  const title = (
    <>
      {showBreadcrumbs && <Breadcrumbs items={breadcrumbsConfig} className={classes.breadcrumbs} />}
      <Text className={classes.title} tagName={titleTag} aria-flowto="tr-timer">
        {titleText}
      </Text>
    </>
  )

  return (
    <RtlProvider>
      <div
        className={st(classes.root, {[layoutSize]: true})}
        data-hook={reservationDetailsDataHooks.root()}
      >
        <div className={classes.contentWrapper}>
          {isAccordionLayout ? title : null}
          <div className={classes.formWrap}>
            {isAccordionLayout ? null : title}
            <Form
              layoutSize={layoutSize}
              currentMember={currentMember}
              showAutoApproveButtonLabel={shouldShowAutoApproveText}
            />
          </div>

          <div className={classes.detailsWrap}>
            {reservation ? <CountdownTimer layoutSize={layoutSize} /> : null}

            <ReservationDetailsSection layoutSize={layoutSize} />
            <ReservationErrorModal />
          </div>
        </div>
      </div>
    </RtlProvider>
  )
}

export default withLayoutSizeProvider(
  withReservationDetailsStorageProvider(Widget),
  LAYOUT_BREAKPOINTS_START,
)
