import {useEffect, useState} from 'react'

import {useReservationDetailsStorage} from '../../storage'
import {RESERVATION_HELD_TIMEOUT_SECONDS} from '../../../../utils/constants'

export const useHooks = () => {
  const {reservation} = useReservationDetailsStorage()

  const reservationCreated = reservation?.createdDate
    ? new Date(reservation.createdDate)
    : new Date()

  const calcTimeLeft = () => {
    const diffSec = (Date.now() - reservationCreated.getTime()) / 1000
    return Math.max(RESERVATION_HELD_TIMEOUT_SECONDS - Math.floor(diffSec), 0)
  }

  const [leftSec, setLeftSec] = useState(calcTimeLeft())

  useEffect(() => {
    const interval = setInterval(() => {
      setLeftSec(calcTimeLeft())
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  })

  return {
    reservation,
    leftSec,
  }
}
