import {createStylesParams, StyleParamType, wixColorParam, wixFontParam} from '@wix/tpa-settings'

export type GeneralStylesParams = {
  backgroundColor: StyleParamType.Color
  borderColor: StyleParamType.Color
  borderWidth: StyleParamType.Number
  borderRadius: StyleParamType.Number
  breadcrumbsFont: StyleParamType.Font
  breadcrumbsColor: StyleParamType.Color
  titleFont: StyleParamType.Font
  titleColor: StyleParamType.Color
  subtitleFont: StyleParamType.Font
  subtitleColor: StyleParamType.Color
  bodyTextFont: StyleParamType.Font
  bodyTextColor: StyleParamType.Color
}

export const generalStyleParams = createStylesParams<GeneralStylesParams>({
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  borderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0),
  },
  borderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  borderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  breadcrumbsFont: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: wixFontParam('Body-M', {size: 16}),
  },
  breadcrumbsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  titleFont: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: (params) =>
      wixFontParam('Page-title', {
        htmlTag: 'h1',
        size: params.isMobile ? 28 : 32,
      })(params),
  },
  titleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  subtitleFont: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: wixFontParam('Page-title', {size: 16, htmlTag: 'h2'}),
  },
  subtitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  bodyTextFont: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: wixFontParam('Body-M', {size: 16}),
  },
  bodyTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
})
