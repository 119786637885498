import {useCallback} from 'react'
import {utcToZonedTime} from '@wix/table-reservations-lib/timezone'
import {useBi, useEnvironment} from '@wix/yoshi-flow-editor'
import {Reservation} from '@wix/ambassador-table-reservations-v1-reservation/types'

import {useReservationDetailsStorage} from '../../storage'
import {getReservationLocationById} from '../../../../utils/reservationLocation'
import {getLogger} from '../../../../utils/getLogger'
import {usePaymentRuleData} from '../../../../utils/usePaymentRuleData'
import {isPaymentTokenizationSupported} from '../../../../utils/paymentTokenization'

export const useHooks = () => {
  const {
    reservationLocations,
    reservation,
    changeReservation,
    regionalSettings,
    isManualApproval,
    reservationDataQueryParams,
    paymentMethods,
  } = useReservationDetailsStorage()

  const bi = useBi()
  const logger = getLogger(bi)
  const {isPreview} = useEnvironment()

  const currentReservationLocation = getReservationLocationById(
    (isManualApproval
      ? reservationDataQueryParams?.reservationLocationId
      : reservation?.details?.reservationLocationId) ?? '',
    reservationLocations,
  )

  const timeZone = currentReservationLocation?.location?.timeZone

  const handleChangeReservationClick = useCallback(() => {
    const reservationToChange = isManualApproval
      ? {
          details: {
            startDate: new Date(reservationDataQueryParams?.startDate!),
            partySize: Number(reservationDataQueryParams?.partySize),
            reservationLocationId: reservationDataQueryParams?.reservationLocationId,
          },
        }
      : reservation

    changeReservation(reservationToChange)
    logger.clickOnChangeReservationDetails({
      isPreview,
      reservation: reservationToChange as Reservation,
      locationId: currentReservationLocation?.location?.id,
      reservationLocationId: currentReservationLocation?.id,
    })
  }, [reservation, changeReservation])

  const startDate = utcToZonedTime(
    isManualApproval ? reservationDataQueryParams?.startDate! : reservation?.details?.startDate!,
    timeZone,
  )

  const partySize = Number(
    isManualApproval ? reservationDataQueryParams?.partySize : reservation?.details?.partySize,
  )

  const {isPaymentRuleActive, paymentRuleFeePerGuest, currency, feePerGuestWithCurrency} =
    usePaymentRuleData(currentReservationLocation, partySize)

  const paymentTokenizationSupported = isPaymentTokenizationSupported(paymentMethods)

  return {
    isManualApproval,
    startDate,
    partySize,
    regionalSettings,
    locationName: currentReservationLocation?.location?.name,
    isPaymentRuleActive,
    paymentRuleFeePerGuest,
    currency,
    feePerGuestWithCurrency,
    paymentTokenizationSupported,
    handleChangeReservationClick,
  }
}
