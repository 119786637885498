import {useTranslation} from '@wix/yoshi-flow-editor'
import React from 'react'
import {SectionNotification} from 'wix-ui-tpa/cssVars'

import cardImageSrc from '../../../../assets/images/card.png'

import {classes} from './PaymentTokenization.st.css'

export const PaymentTokenization: React.FC = () => {
  const {t} = useTranslation()

  return (
    <SectionNotification type="alert" className={classes.paymentTokenization}>
      <SectionNotification.Icon
        icon={<img className={classes.paymentTokenizationImage} src={cardImageSrc} alt="card" />}
      />
      <SectionNotification.Text>
        {t('uou-reservations.reservation-details.payment-tokenization')}
      </SectionNotification.Text>
    </SectionNotification>
  )
}
