import React from 'react'

import {ReserveReservationApplicationErrorCode} from '../../actions/reserveReservation'
import {ErrorModal} from '../../../../components-shared/ErrorModal'

import {useHooks} from './useHooks'

interface Texts {
  title: string
  text: string
  ctaButton: string
}

const DEFAULT_TEXTS: Texts = {
  title: 'uou-reservations.reservation-details.error-modal.title',
  text: 'uou-reservations.reservation-details.error-modal.text',
  ctaButton: 'uou-reservations.reservation-details.error-modal.cta',
}

const APPLICATION_ERROR_CODE_TO_TEXTS: Record<ReserveReservationApplicationErrorCode, Texts> = {
  [ReserveReservationApplicationErrorCode.HELD_EXPIRED]: {
    title: 'uou-reservations.reservation-details.held-modal.title',
    text: 'uou-reservations.reservation-details.held-modal.text',
    ctaButton: 'uou-reservations.reservation-details.held-modal.cta',
  },
  [ReserveReservationApplicationErrorCode.TOO_MANY_REQUESTS]: {
    title: 'uou-reservations.reservation-details.too-many-requests-modal.title',
    text: 'uou-reservations.reservation-details.too-many-requests-modal.text',
    ctaButton: 'uou-reservations.reservation-details.too-many-requests-modal.cta',
  },
}

export const ReservationErrorModal: React.FC = () => {
  const {isModalOpen, onClose, onCtaButtonClick, apiRequestErrorDetails} = useHooks()

  const getErrorTexts = () => {
    if (apiRequestErrorDetails?.applicationCode) {
      return (
        APPLICATION_ERROR_CODE_TO_TEXTS[apiRequestErrorDetails.applicationCode] ?? DEFAULT_TEXTS
      )
    }

    return DEFAULT_TEXTS
  }

  const {title, text, ctaButton} = getErrorTexts()

  return (
    <ErrorModal
      isModalOpen={isModalOpen}
      title={title}
      text={text}
      ctaButton={ctaButton}
      onClose={onClose}
      onCtaButtonClick={onCtaButtonClick}
    />
  )
}
